import axios from "axios";
import Util from "../Util";
const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log(BASE_URL)
async function apiCallBodyDataPost(url, bodyData, customSchoolId) {
  // console.log( BASE_URL + url);
  const user = await Util.getUser();
  let header = {};
  if (Util.isValidData(user)) {
    header.user = user.id;
  }
  const selectedSchool = await Util.getSelectedSchool();
  if (Util.isValidData(selectedSchool)) {
    header.school = selectedSchool.id;
  } else {
    const ParentMode = Util.getParentMode();

    if (ParentMode) {
      header.school = customSchoolId;
    }
  }
  // console.log("===>", header);
  return axios({
    url: BASE_URL + url,
    method: "POST",
    data: bodyData,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      // "Content-Type": "application/json",
      user: header.user,
      school: header.school,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        return "ERR_NETWORK";
      } else if (error.code === "ERR_BAD_REQUEST") {
        return {
          data: { success: false, message: error.response.data.message },
        };
      } else {
        return error;
      }
    });
}

async function apiCallBodyDataGet(url, id) {
  const user = await Util.getUser();
  let header = {};
  if (Util.isValidData(user)) {
    header.user = user.id;
  }
  const selectedSchool = await Util.getSelectedSchool();
  if (Util.isValidData(selectedSchool)) {
    header.school = selectedSchool.id;
  } else {
    const ParentMode = Util.getParentMode();
    if (ParentMode) {
      header.school = id;
    }
  }
  try {
    return axios({
      url: BASE_URL + url,
      method: "GET",
      // data: bodyData,
      // headers: header,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
        user: header.user,
        school: header.school,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          return "ERR_NETWORK";
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}

async function apiCallParamsPost(url) {
  const user = await Util.getUser();
  let header = {};
  if (Util.isValidData(user)) {
    header.user = user.id;
  }
  const selectedSchool = await Util.getSelectedSchool();
  if (Util.isValidData(selectedSchool)) {
    header.school = selectedSchool.id;
  }

  return axios
    .post(BASE_URL + url, {}, { 
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
        user: header.user,
        school: header.school,
      },
     })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        return "ERR_NETWORK";
      } else {
        return error;
      }
    });
}

async function apiCallParamsGet(url) {
  const user = await Util.getUser();
  let header = {};
  if (Util.isValidData(user)) {
    header.user = user.id;
  }
  const selectedSchool = await Util.getSelectedSchool();
  if (Util.isValidData(selectedSchool)) {
    header.school = selectedSchool.id;
  }
  return axios
    .get(BASE_URL + url, { 
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
        user: header.user,
        school: header.school,
      },
     })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        return "ERR_NETWORK";
      } else {
        return error;
      }
    });
}

async function apiCallDelete(url, id) {
  const user = await Util.getUser();
  let header = {};
  if (Util.isValidData(user)) {
    header.user = user.id;
  }
  const selectedSchool = await Util.getSelectedSchool();

  if (Util.isValidData(selectedSchool)) {
    header.school = selectedSchool.id;
  } else {
    const ParentMode = Util.getParentMode();
    if (ParentMode) {
      header.school = id;
    }
  }

  return axios({
    url: BASE_URL + url,
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
      user: header.user,
      school: header.school,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        return "ERR_NETWORK";
      } else {
        return error;
      }
    });
}

export default {
  apiCallBodyDataPost,
  apiCallBodyDataGet,
  apiCallParamsPost,
  apiCallParamsGet,
  apiCallDelete,
};
